















































import { Observer } from 'mobx-vue'
import { Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import ClaimerDetailOwnerViewModel from '../viewmodels/claimer-detail-owner-viewmodel'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({
  components: {}
})
export default class AddWinnerDialog extends Vue {
  @Inject() vm!: ClaimerDetailOwnerViewModel
  @Prop({ default: false }) state
  amount = ''
  address = ''

  async save() {
    try {
      if (!(this.$refs.form as any).validate()) return
      this.vm.changeEditDialogLoading(true)
      await this.vm.onEditWinner({ address: this.address, value: this.amount })
      snackController.success('Winner added successfully')
      this.vm.requestAddWinnerDialog(false)
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.vm.changeEditDialogLoading(false)
    }
  }
  async resetDefault() {
    if (this.$refs.form) (this.$refs.form as any).reset()
    this.amount = ''
    this.address = ''
  }

  @Watch('state', { immediate: true }) changeState(value) {
    if (value) {
      this.resetDefault()
    }
  }
}
